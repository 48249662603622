import { getAppStatus, registerApplication, start } from "single-spa";
import { constructApplications, constructRoutes, constructLayoutEngine } from "single-spa-layout";
import microfrontendLayout from "./microfrontend-layout.html";

const routes = constructRoutes(microfrontendLayout);
const applications = constructApplications({
    routes,
    loadApp({ name }) {
        console.log(name);

        // Hide sidebars when navigated to login page
        if (name === "@eppsERP/erp-login") {
            updateSidebarVisibility();
        }
        return System.import(name);
    },
});
const layoutEngine = constructLayoutEngine({ routes, applications });

applications.forEach(registerApplication);
layoutEngine.activate();
start();

function updateSidebarVisibility() {
    const sidebar = document.getElementById("mainNavbar");
    const internalRouteSection = document.getElementsByClassName("internalRoutes") as HTMLCollectionOf<HTMLElement>;
    const path = window.location.pathname;
    const approot = document.getElementById("app-root");
    if (path === "/") {
        sidebar.style.display = "none";
        if (internalRouteSection[0]) internalRouteSection[0].style.margin = "0px";
        approot.style.width = "100%";
    } else {
        sidebar.style.display = "block";
        approot.style.width = "100%";
        internalRouteSection[0].style.margin = "60px 55px 0 58px";
    }
}

// Initial check
updateSidebarVisibility();

// Listen for single-spa navigation events
window.addEventListener("single-spa:routing-event", updateSidebarVisibility);

async function handle404(location) {
    const path = location.pathname;
     const applications = [
        "admin",
        "yus",
        "bms",
        "vms",
        "rpp",
        "tks",
        "mpb",
        "swm",
        "btms",
        "pims",
        "fms",
        "fmms",
        "eseva",
        "e-seva",
        "opdef",
        "qcp",
        "dry",
        "esamagri",
        "quartering",
        "training",
        "yam",
        "mhms",
        "ysm",
        "mis",
        "qam",
        "sfmm",
        "leave",
        "opra",
        "personnel",
        "mms",
        "tyduty",
        "qcms",
        "dl"
    ];
    const appName = applications.find((appName) => path.startsWith(`/${appName}`));
    console.log(appName)
    if (appName) {
        let appStatus;
        switch (appName) {
            case 'personnel':
                appStatus = await getAppStatus('@eppsERP/erp-pims');
                break;
            case 'fmms':
                appStatus = await getAppStatus('@eppsERP/erp-fms');
                break;
            case 'qcms':
            case 'dl':
            case 'opra':
            case 'opdef':
            case 'btms':
                appStatus = await getAppStatus('@eppsERP/erp-rpp');
                break;
            default:
                appStatus = await getAppStatus('@eppsERP/erp-'+appName);
                break;
        }

        if (appStatus !== 'MOUNTED') {
            // Redirect to a static 404 page
            window.location.href = "/404-pageNotFound"; // Replace with the actual path to your 404.html file
        }

    } else {
        // Redirect to a static 404 page if no valid route is found
        // window.location.href = "/404.html"; // Replace with the actual path to your 404.html file
    }
}

// Call handle404 when single-spa is started
window.addEventListener("single-spa:routing-event", () => handle404(window.location));
